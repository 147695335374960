<template>
     <div>
        <div>
            <label class="block text-900 font-medium text-xl mb-2">{{this.forms[0].pertanyaan}}</label>
            <Dropdown v-model="forms[0].jawaban" required="true" :options="dataDropdownFrekuensi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban"/>

            <div v-if="forms[0].jawaban == '7 Keatas'">
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[1].pertanyaan}}</label>
                <Dropdown v-model="forms[1].jawaban" required="true" :options="dataDropdownSelingan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" />
            </div>

            <div v-if="forms[0].jawaban == '1-6'">
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[2].pertanyaan}}</label>
                <Dropdown v-model="forms[2].jawaban" required="true" :options="dataDropdownSelingan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" />
            </div>

            <div v-if="forms[0].jawaban == '0'">
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[3].pertanyaan}}</label>
                <Dropdown v-model="forms[3].jawaban" required="true" :options="dataDropdownKekurangan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" @change="forms[3].others = null" />
                <InputText required="true" v-model.trim="forms[3].others" type="text" class="w-full mb-4" placeholder="Tuliskan alasan" style="padding:1rem;" v-if="forms[3].jawaban == 'Others'"/>
            </div>
            <Button label="Lanjutkan" @click="saveNew" class="w-full p-3 text-xl mt-3" :disabled="loading"></Button>
        </div>
     </div>
</template>

<script>
export default {
    props: [
		"tahapan",
		"noWa",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownKekurangan: [
                {name: 'Tidak suka dengan rasa yang ditawarkan', code: 'Tidak suka dengan rasa yang ditawarkan'},
                {name: 'Tidak tertarik untuk membeli', code: 'Tidak tertarik untuk membeli'},
                {name: 'Lebih suka kopi hitam yang biasa saya konsumsi', code: 'Lebih suka kopi hitam yang biasa saya konsumsi'},
                {name: 'Stock belum habis', code: 'Stock belum habis'},
                {name: 'Others', code: 'Others'},
            ],
            dataDropdownFrekuensi: [
                {name: '0', code: '0'},
                {name: '1-6', code: '1-6'},
                {name: '7 Keatas', code: '7 Keatas'},
            ],
            dataDropdownSelingan: [
                {name: 'Ya', code: 'Ya'},
                {name: 'Tidak', code: 'Tidak'},
            ],

            forms: [
                {
                    "kode_pertanyaan": "S1P009",
                    "pertanyaan": "Setelah menerima sample Gilus Harum, berapa kali anda pernah melakukan pembelian di warung / kantin / spg",
                    "jawaban": null,
                    "urutan": "1",
                    "section": 3,
                },
                {
                    "kode_pertanyaan": "S1P010",
                    "pertanyaan": "Apakah Gilus Harum saat ini sudah menjadi satu-satunya kopi hitam yang anda konsumsi?",
                    "jawaban": null,
                    "urutan": "2",
                    "section": 4,
                },
                {
                    "kode_pertanyaan": "S1P011",
                    "pertanyaan": "Apakah Gilus Harum saat ini sudah menjadi satu-satunya kopi hitam yang anda konsumsi?",
                    "jawaban": null,
                    "urutan": "3",
                    "section": 5,
                },
                {
                    "kode_pertanyaan": "S1P012",
                    "pertanyaan": "Kenapa anda belum/tidak melakukan pembelian Gilus Harum?",
                    "jawaban": null,
                    "urutan": "4",
                    "others": null,
                    "section": 6,
                },
            ],

            page: 1,
        }
    },
    methods: {
        saveNew(){

            if(this.forms[0].jawaban == '7 Keatas'){
                this.forms[2].jawaban = null;
                this.forms[3].jawaban = null;
                this.forms[3].others = null;

                if(this.forms[1].jawaban === null || this.forms[1].jawaban === ''){
                    alert('harap isi yang kosong');
                    return;
                }
            }else if(this.forms[0].jawaban == '1-6'){
                this.forms[1].jawaban = null;
                this.forms[3].jawaban = null;
                this.forms[3].others = null;

                if(this.forms[2].jawaban === null || this.forms[2].jawaban === ''){
                    alert('harap isi yang kosong');
                    return;
                }
            }else{
                this.forms[1].jawaban = null;
                this.forms[2].jawaban = null;

                if((this.forms[3].jawaban === null || this.forms[3].jawaban === '') || this.forms[3].jawaban == 'Others' && (this.forms[3].others === null || this.forms[3].others === '')){
                    alert('harap isi yang kosong');
                    return;
                }
            }

            this.loading = true;
            
            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/simpan-jawaban',
                data: {
                    "status": 'survey 3',
                    "tahap": this.tahapan,
                    "no_hp": this.noWa,
                    "answer": this.forms,
                    "isHadiah": 0,
                },
            })
            .then((res) => {
                this.data = res.data;
                setTimeout(() => {
                    this.$router.push({ name: "terimakasih" });
                    this.loading = false;
                }, 300);
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        saveNewTesting(){
            this.$router.push({ name: "terimakasih" });
            alert('hore berhasil');
        }
    }
}
</script>