<template>
    <div v-if="reloadForm == false">
        <div v-if="is_exist == true">     
            <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                <div class="font-bold mr-8">SURVEY APP</div>
            </div>
            <Error :errors="errors"/>
            <div class="grid">
                <div class="field col-12 lg:col-6 lg:col-offset-3">
                    <div class="m-5 surface-card p-4 shadow-2 border-round">
                        <div class="text-center mb-5">
                            <img src="/favicon.png" alt="Image" height="50" class="mb-3">
                            <div class="text-900 text-3xl font-medium mb-3">Selamat Datang Dalam Market Test <b>Gilus Harum</b></div>
                            <span class="text-600 font-medium line-height-3">Silahkan jawab pertanyaan di bawah ini</span>
                            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Gilus Harum!</a>
                            <br>
                            <span class="text-600 font-bold line-height-3">No. WA {{this.no_wa}} </span>
                        </div>
                        <hr>
                        <div class="w-full md:w-10 mx-auto">
                            <div v-if="survey == '1'">
                                <div v-if="tahapan == '2'">
                                    <PertanyaanS1T2 :tahapan="tahapan" :noWa="no_wa"/>
                                </div>
                                <div v-else-if="tahapan == '3'">
                                    <PertanyaanS1T3 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                            </div>
                            <div v-else-if="survey == '2'">
                                <div v-if="tahapan == '2'">
                                    <PertanyaanS2T2 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                                <div v-else-if="tahapan == '3'">
                                    <PertanyaanS2T3 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                            </div>
                            <div v-else-if="survey == '3'">
                                <div v-if="tahapan == '2'">
                                    <PertanyaanS3T2 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                                <div v-else-if="tahapan == '3'">
                                    <PertanyaanS3T3 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                                <div v-else-if="tahapan == '4'">
                                    <PertanyaanS3T4 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                            </div>
                            <div v-else-if="survey == '4'">
                                <div v-if="tahapan == '2'">
                                    <PertanyaanS4T2 :tahap="tahapan" :noWa="no_wa" />
                                </div>
                                <div v-else-if="tahapan == '3'">
                                    <PertanyaanS4T3 :tahapan="tahapan" :noWa="no_wa" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_exist == false">
            <NoAccess />
        </div>
    </div>
    <div v-else>
        <ReloadForm />
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import PertanyaanS1T2 from '../../../../components/new/survey/tahap-survey1/2.vue';
import PertanyaanS1T3 from '../../../../components/new/survey/tahap-survey1/3.vue';
import PertanyaanS2T2 from '../../../../components/new/survey/tahap-survey2/2.vue';
import PertanyaanS2T3 from '../../../../components/new/survey/tahap-survey2/3.vue';
import PertanyaanS3T2 from '../../../../components/new/survey/tahap-survey3/2.vue';
import PertanyaanS3T3 from '../../../../components/new/survey/tahap-survey3/3.vue';
import PertanyaanS3T4 from '../../../../components/new/survey/tahap-survey3/4.vue';
import PertanyaanS4T2 from '../../../../components/new/survey/tahap-survey4/2.vue';
import PertanyaanS4T3 from '../../../../components/new/survey/tahap-survey4/3.vue';
import NoAccess from '../../../../components/new/survey/NoAccess.vue';
import ReloadForm from '../../../../components/new/survey/ReloadForm.vue';
import Error from '../../../../components/Error.vue';


export default {
    components: {
        'PertanyaanS1T2': PertanyaanS1T2,
        'PertanyaanS1T3': PertanyaanS1T3,
        'PertanyaanS2T2': PertanyaanS2T2,
        'PertanyaanS2T3': PertanyaanS2T3,
        'PertanyaanS3T2': PertanyaanS3T2,
        'PertanyaanS3T3': PertanyaanS3T3,
        'PertanyaanS3T4': PertanyaanS3T4,
        'PertanyaanS4T2': PertanyaanS4T2,
        'PertanyaanS4T3': PertanyaanS4T3,
        'NoAccess': NoAccess,
        'ReloadForm': ReloadForm,
		'Error': Error,
    },
    data() {
        return {
            // loading
            reloadForm: false,
            loading: false,

            no_wa: null,
            tahapan: null,
            is_exist: false,
            msg: null,
        }
    },
    created() {
        this.getItemById(this.$route.params.id, this.$route.params.tahapan, this.$route.params.survey);
        this.no_wa = this.$route.params.id;
        this.tahapan = this.$route.params.tahapan;
        this.survey = this.$route.params.survey;
    },
	mounted() {
        this.$store.commit('setErrors', {});
	},
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        // checkPeserta
        getItemById(id, tahapan, survey) {
            this.reloadForm = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/get-survey',
                data: {
                    "status": 'survey ' + survey,
                    "no_hp": id,
                },
            })
            .then((res) => {
                this.reloadForm = false;
                this.data = res.data;

                if (res) {
                                        
                   if(this.data.message == "Peserta tidak ditemukan" || this.data.message == "Survey sudah di isi" ){    
                        this.is_exist = false;
                        return;
                   }else if(this.data.message == "Hadiah sudah diterima"){
                        this.is_exist = true;
                        this.is_finish = false;
                        return;
                   }
                }
            })
            .catch((err) => {
                console.log(err);
                this.reloadForm = false;
            });
        },
    },
}
</script>