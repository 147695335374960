<template>
        <div v-if="this.page == 1">
            <!-- FIRST QUESTION -->
            <div>
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[0].pertanyaan}}</label>
                <Dropdown v-model="forms[0].jawaban" required="true" :options="dataDropdownKonsumsi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban"/>

                <Button label="Lanjutkan" @click="nextPage" class="w-full p-3 text-xl mt-3" :disabled="loading"></Button>
            </div>
        </div>
        <div v-if="this.page == 2">
            <div>
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[1].pertanyaan}}</label>
                <Dropdown v-model="forms[1].jawaban" required="true" :options="dataDropdownKelebihan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" @change="forms[1].others = null"/>
                <InputText required="true" v-model.trim="forms[1].others" type="text" class="w-full mb-4" placeholder="Tuliskan alasan" style="padding:1rem;" v-if="forms[1].jawaban == 'Others'"/>

                <label class="block text-900 font-medium text-xl mb-2">Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda?</label>
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[2].label}}</label>
                <InputText required="true" v-model.trim="forms[2].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[3].label}}</label>
                <InputText required="true" v-model.trim="forms[3].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[4].label}}</label>
                <InputText required="true" v-model.trim="forms[4].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[5].label}}</label>
                <InputText required="true" v-model.trim="forms[5].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[6].label}}</label>
                <InputText required="true" v-model.trim="forms[6].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[7].label}}</label>
                <InputText required="true" v-model.trim="forms[7].jawaban" type="text" class="w-full mb-4" placeholder="Tuliskan masukan" style="padding:1rem;"/>

                <Button label="Kembali" @click="previousPage" class="w-full p-3 text-xl mt-3 p-button-secondary"></Button>
                <Button label="Lanjutkan" @click="nextPage" class="w-full p-3 text-xl mt-3" :disabled="loading"></Button>
            </div>
        </div>
        <div v-if="this.page == 3">
            <div>
                <label class="block text-900 font-medium text-xl mb-2">{{this.forms[8].pertanyaan}}</label>
                <Dropdown v-model="forms[8].jawaban" required="true" :options="dataDropdownFrekuensi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban"/>

                <div v-if="forms[8].jawaban == '7 Keatas'">
                    <label class="block text-900 font-medium text-xl mb-2">{{this.forms[9].pertanyaan}}</label>
                    <Dropdown v-model="forms[9].jawaban" required="true" :options="dataDropdownSelingan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" />
                </div>

                <div v-if="forms[8].jawaban == '1-6'">
                    <label class="block text-900 font-medium text-xl mb-2">{{this.forms[10].pertanyaan}}</label>
                    <Dropdown v-model="forms[10].jawaban" required="true" :options="dataDropdownSelingan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" />
                </div>

                <div v-if="forms[8].jawaban == '0'">
                    <label class="block text-900 font-medium text-xl mb-2">{{this.forms[11].pertanyaan}}</label>
                    <Dropdown v-model="forms[11].jawaban" required="true" :options="dataDropdownKekurangan" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="Pilih Jawaban" @change="forms[11].others = null" />
                    <InputText required="true" v-model.trim="forms[11].others" type="text" class="w-full mb-4" placeholder="Tuliskan alasan" style="padding:1rem;" v-if="forms[11].jawaban == 'Others'"/>
                </div>

                <Button label="Kembali" @click="previousPage" class="w-full p-3 text-xl mt-3 p-button-secondary"></Button>
                <Button label="Lanjutkan" @click="nextPage" class="w-full p-3 text-xl mt-3" :disabled="loading"></Button>
            </div>
        </div>
</template>

<script>
export default {
    props: [
		"tahapan",
		"noWa",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownKelebihan: [
                {name: 'Aromanya yang harum', code: 'Aromanya yang harum'},
                {name: 'Ampasnya lebih cepat turun', code: 'Ampasnya lebih cepat turun'},
                {name: 'Rasa kopi yang nendang', code: 'Rasa kopi yang nendang'},
                {name: 'Rasa manisnya pas', code: 'Rasa manisnya pas'},
                {name: 'Tidak bikin perut kembung', code: 'Tidak bikin perut kembung'},
                {name: 'Others', code: 'Others'},
            ],
            dataDropdownKekurangan: [
                {name: 'Tidak suka dengan rasa yang ditawarkan', code: 'Tidak suka dengan rasa yang ditawarkan'},
                {name: 'Tidak tertarik untuk membeli', code: 'Tidak tertarik untuk membeli'},
                {name: 'Lebih suka kopi hitam yang biasa saya konsumsi', code: 'Lebih suka kopi hitam yang biasa saya konsumsi'},
                {name: 'Others', code: 'Others'},
            ],
            dataDropdownKonsumsi: [
                {name: '0', code: '0'},
                {name: '1', code: '1'},
                {name: '2', code: '2'},
                {name: '3', code: '3'},
                {name: '4', code: '4'},
                {name: '5', code: '5'},
            ],
            dataDropdownFrekuensi: [
                {name: '0', code: '0'},
                {name: '1-6', code: '1-6'},
                {name: '7 Keatas', code: '7 Keatas'},
            ],
            dataDropdownSelingan: [
                {name: 'Ya', code: 'Ya'},
                {name: 'Tidak', code: 'Tidak'},
            ],

            forms: [
                {
                    "kode_pertanyaan": "S1P001",
                    "pertanyaan": "Dari sample Gilus Harum sebelumnya berapa sachet yang sudah anda konsumsi?",
                    "jawaban": null,
                    "urutan": "1",
                    "section": null,
                },
                {
                    "kode_pertanyaan": "S1P002",
                    "pertanyaan": "Apa yang anda suka dari Gilus Harum?",
                    "jawaban": null,
                    "urutan": "2",
                    "others": null,
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P003",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (Kemasan)",
                    "label": "(Kemasan)",
                    "jawaban": null,
                    "urutan": "3",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P004",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (Harga)",
                    "label": "(Harga)",
                    "jawaban": null,
                    "urutan": "4",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P005",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (Aroma)",
                    "label": "(Aroma)",
                    "jawaban": null,
                    "urutan": "5",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P006",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (Ampas)",
                    "label": "(Ampas)",
                    "jawaban": null,
                    "urutan": "6",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P007",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (Rasa)",
                    "label": "(Rasa)",
                    "jawaban": null,
                    "urutan": "7",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P008",
                    "pertanyaan": "Apa masukan anda agar Gilus Harum bisa menjadi kopi favorit anda? (After taste/kesan setelah minum)",
                    "label": "(After taste/kesan setelah minum)",
                    "jawaban": null,
                    "urutan": "8",
                    "section": 1,
                },
                {
                    "kode_pertanyaan": "S1P009",
                    "pertanyaan": "Setelah menerima sample Gilus Harum, berapa kali anda pernah melakukan pembelian di warung / kantin / spg",
                    "jawaban": null,
                    "urutan": "9",
                    "section": 3,
                },
                {
                    "kode_pertanyaan": "S1P010",
                    "pertanyaan": "Apakah Gilus Harum saat ini sudah menjadi satu-satunya kopi hitam yang anda konsumsi?",
                    "jawaban": null,
                    "urutan": "10",
                    "section": 4,
                },
                {
                    "kode_pertanyaan": "S1P011",
                    "pertanyaan": "Apakah Gilus Harum saat ini sudah menjadi satu-satunya kopi hitam yang anda konsumsi?",
                    "jawaban": null,
                    "urutan": "11",
                    "section": 5,
                },
                {
                    "kode_pertanyaan": "S1P012",
                    "pertanyaan": "Kenapa anda belum/tidak melakukan pembelian Gilus Harum?",
                    "jawaban": null,
                    "urutan": "12",
                    "others": null,
                    "section": 6,
                },
            ],

            page: 1,
        }
    },
    methods: {
        nextPage(){
            if(this.forms[0].jawaban == null){
                alert('Silahkan isi Jawaban Terlebih Dahulu');
                return
            }
            if(this.forms[0].jawaban == '0'){
                this.saveNew();
                return
            }
            if(this.page == '2'){
                if(this.forms[0].jawaban != '5'){
                    this.saveNew();
                    return
                }
            }

            if(this.page == '3'){
                this.saveNew();
                return
            }

            this.page++;
            
        },
        previousPage(){
            this.page--;
        },
        saveNew(){
            if(this.forms[0].jawaban == 0){
                for (let index = 0; index < this.forms.length; index++) {
                    if(index !== 0){
                        this.forms[index].jawaban = null;
                        this.forms[index].others = null;
                        // console.log(index + ' ' +this.forms[index].pertanyaan + ' : ' + this.forms[index].jawaban);
                        // console.log('others :' + this.forms[index].others);
                    }
                }
            }else if(this.forms[0].jawaban < 5){
                const cek = [8,9,10,11] //item yang tidak masuk di jawaban kurang dari 5
                
                for (let index = 0; index < this.forms.length; index++) {
                    if (cek.includes(index)){
                        this.forms[index].jawaban = null;
                        this.forms[index].others = null;
                        // console.log(index + this.forms[index].pertanyaan + ' : ' + this.forms[index].jawaban);
                        // console.log('others :' + this.forms[index].others);
                    }
                }

                for (let index = 0; index < this.forms.length; index++) {
                    if (!cek.includes(index)){
                        if(this.forms[index].jawaban == 'Others' && (this.forms[index].others === null || this.forms[index].others === "")){
                            alert('harap isi yang kosong');
                            return
                        }
                        if(this.forms[index].jawaban == null || this.forms[index].jawaban == ''){
                            alert('harap isi yang kosong');
                            return
                        }
                    }
                }
            }else{
                for (let index = 0; index < this.forms.length; index++) {
                    if (this.forms[index].jawaban == 'Others' && (this.forms[index].others === null || this.forms[index].others === '')) {
                        alert('harap isi yang kosong');
                        return;
                    }
                    
                    
                    if (this.forms[8].jawaban == '7 Keatas' && (index === 10 || index === 11)) {
                        this.forms[index].jawaban = null;
                        this.forms[index].others = null;
                        console.log(index + ' ' + this.forms[index].jawaban);
                        continue; // Skip further checks if forms[8].jawaban is '7 Keatas' and index is 10 or 11
                    }else if (this.forms[8].jawaban == '1-6' && (index === 9 || index === 11)) {
                        this.forms[index].jawaban = null;
                        this.forms[index].others = null;
                        console.log(index + ' ' + this.forms[index].jawaban);
                        continue; // Skip further checks if forms[8].jawaban is '1-6' and index is 9 or 11
                    }else if (this.forms[8].jawaban == '0' && (index === 9 || index === 10)) {
                        this.forms[index].jawaban = null;
                        this.forms[index].others = null;
                        console.log(index + ' ' + this.forms[index].jawaban);
                        continue; // Skip further checks if forms[8].jawaban is '0' and index is 9 or 10
                    }else{
                        if (this.forms[index].jawaban == null || this.forms[index].jawaban === '') {
                            alert('harap isi yang kosong');
                            return;
                        }
                    }
                }

            }

            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/simpan-jawaban',
                data: {
                    "status": 'survey 1',
                    "tahap": this.tahapan,
                    "no_hp": this.noWa,
                    "answer": this.forms,
                    "isHadiah": 0,
                },
            })
            .then((res) => {
                this.data = res.data;
                setTimeout(() => {
                    this.$router.push({ name: "terimakasih" });
                    this.loading = false;
                }, 300);
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        saveNewTesting(){
            this.$router.push({ name: "terimakasih" });
            alert('hore berhasil');
        }
    },
}
</script>
